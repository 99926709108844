/* eslint-disable no-param-reassign */
import { createPage, createFlow } from '../utils';
import { END } from '../variables';
import { REGISTRATION_ERRORS } from '../../errors';
import i18n from '../../../plugins/i18n';

import { FIRST_NAME, LAST_NAME, DATE_OF_BIRTH, GENDER, PRIMARY_COMPLAINT } from '../../store';

const RegisterVisit = createPage({
  name: 'RegisterVisit',
  nextPage: 'RegisterSchedule',
});
const RegisterSchedule = createPage({
  name: 'RegisterSchedule',
  nextPage: 'RegisterIntroduce',
});
const RegisterIntroduce = createPage({
  name: 'RegisterIntroduce',
  nextPage: 'RegisterInform',
  options: {
    pageConfig: {
      isEmailOptional: true,
      showEmail: true,
    },
  },
});
const RegisterInform = createPage({
  name: 'RegisterInform',
  nextPage: 'RegisterConfirm',
});
const RegisterConfirm = createPage({
  name: 'RegisterConfirm',
  nextPage: 'RegisterIntakeChoice',
});

const RegisterIntakeChoice = createPage({
  name: 'RegisterIntakeChoice',
  nextPage: 'RegisterSurvey',
  options: {
    pageConfig: {
      PROD_FORMS: {
        simple: 'p_feW2vbaldxB2EYi1JkKH', // sentry-health tenant
        detailed: 'p_oIulqXkmGYhuYUAlA0Cz', // sentry-health tenant
      },
    },
  },
});
const RegisterSurvey = createPage({
  name: 'RegisterSurvey',
  nextPage: 'RegisterComplete',
  options: {
    backButtonHeader: true,
    pageConfig: {
      pageTitle: 'Tell us a bit about your medical history',
      pageSubtitle:
        'Having the right information for your medical assessment is very important. This will help speed up your appointment.',
      onSurveyCreate: (state, survey) => {
        survey.data = {
          ...survey.data,
          firstName: FIRST_NAME(state),
          lastName: LAST_NAME(state),
          gender: GENDER(state),
          primaryComplaint: PRIMARY_COMPLAINT(state),
          dateOfBirth: DATE_OF_BIRTH(state),
        };
      },
    },
  },
});

const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: 'RegisterLogout',
  options: {
    pageConfig: {
      buttonText: i18n.t('main.continue'),
      cardText: i18n.t('registration.registerComplete.trt.cardText'),
      cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
      isEndOfRegistration: true,
      checkForExistingUser: true,
    },
  },
});
const RegisterLogout = createPage({
  name: 'RegisterLogout',
  nextPage: 'RegisterAssessment',
});
const RegisterAssessment = createPage({
  name: 'RegisterAssessment',
  nextPage: 'RegisterThankyou',
});

const RegisterThankyou = createPage({
  name: 'RegisterThankyou',
  nextPage: END,
});

const grace = createFlow({
  landing: 'RegisterVisit',
  onError: (errorType) => {
    if (errorType === REGISTRATION_ERRORS.EMAIL_TAKEN) {
      return 'RegisterIntroduce';
    }
    if (errorType === REGISTRATION_ERRORS.CREATE_USER) {
      return 'RegisterIntroduce';
    }
    return 'RegisterSchedule';
  },
  flow: {
    RegisterAssessment,
    RegisterComplete,
    RegisterConfirm,
    RegisterInform,
    RegisterIntakeChoice,
    RegisterIntroduce,
    RegisterLogout,
    RegisterSchedule,
    RegisterSurvey,
    RegisterThankyou,
    RegisterVisit,
  },
});

export default grace;
