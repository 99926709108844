import { createPage, createFlow } from '../utils';
import { REPEAT } from '../variables';
import { REGISTRATION_ERRORS } from '../../errors';

const RegisterCovidLanding = createPage({
  name: 'RegisterCovidLanding',
  nextPage: 'RegisterIntroduce',
  options: {
    showFooter: false,
  },
});

const RegisterIntroduce = createPage({
  name: 'RegisterIntroduce',
  nextPage: 'RegisterInform',
});

const RegisterInform = createPage({
  name: 'RegisterInform',
  nextPage: 'RegisterSchedule',
  options: {
    pageConfig: {
      form: {
        questionTwo: {
          variableName: 'nextAppointmentDate',
          displayText: null,
          noteLabel: 'Has date for next appointment?',
          infoText: null,
          show: true,
          value: null,
        },
        questionThree: {
          variableName: 'pastEffects',
          displayText: null,
          noteLabel: 'Has experienced adverse effects from a previous dose?',
          infoText: null,
          show: true,
          value: null,
        },
      },
      isReasonForVisitFixed: true,
      reasonForVisit: 'Covid-19 Vaccine Assessment',
    },
  },
});

const RegisterSchedule = createPage({
  name: 'RegisterSchedule',
  nextPage: 'RegisterComplete',
  options: {
    pageConfig: {
      bufferMinutesV2: 2880,
    },
  },
});

const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: 'RegisterLogout',
});
const RegisterLogout = createPage({
  name: 'RegisterLogout',
  nextPage: REPEAT,
});

const covidcare = createFlow({
  landing: 'RegisterCovidLanding',
  onError: (errorType) => {
    if (
      errorType === REGISTRATION_ERRORS.EMAIL_TAKEN ||
      errorType === REGISTRATION_ERRORS.CREATE_USER
    ) {
      return 'RegisterIntroduce';
    }

    return 'RegisterSchedule';
  },
  flow: {
    RegisterCovidLanding,
    RegisterIntroduce,
    RegisterInform,
    RegisterSchedule,
    RegisterComplete,
    RegisterLogout,
  },
});

export default covidcare;
