/* eslint-disable no-param-reassign */
import { createPage, createFlow } from '../utils';
import i18n from '../../../plugins/i18n';
import { END } from '../variables';
import { PROD, QA, DEV } from '../../env';
import getEnvironment from '../../../utils/environment';

import {
  ENROLLED_IN_COMPREHENSIVE_CARE,
  IS_RETURNING_PATIENT,
  FIRST_NAME,
  LAST_NAME,
  DATE_OF_BIRTH,
  GENDER,
  PRIMARY_COMPLAINT,
} from '../../store';

const SIMPLE = 'simple';
const DETAILED = 'detailed';

const RegisterNewReturning = createPage({
  name: 'RegisterNewReturning',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return 'RegisterIntroduceReturning';
    }
    return 'RegisterIntroduceNew';
  },
  options: {
    showFooter: false,
  },
});

const RegisterIntroduceNew = createPage({
  name: 'RegisterIntroduceNew',
  componentName: 'RegisterIntroduce',
  nextPage: 'RegisterInform',
  options: {
    pageConfig: {
      isEmailOptional: true,
      showEmail: true,
    },
  },
});
const RegisterIntroduceReturning = createPage({
  name: 'RegisterIntroduceReturning',
  componentName: 'RegisterIntroduce',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return 'RegisterInform';
    }
    return 'RegisterIntroduceNew';
  },
  options: {
    pageConfig: {
      checkUserAttempts: 3,
      checkForExistingUser: true,
      isEmailOptional: true,
      showEmail: false,
      showFirstName: false,
      showLastName: false,
      showDob: true,
      showPhone: false,
      showGender: false,
      healthCardVersionDisabled: true,
      healthCardExpiryDisabled: true,
    },
  },
});
const RegisterInform = createPage({
  name: 'RegisterInform',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return 'RegisterSchedule';
    }
    return 'RegisterSurvey';
  },
  options: {
    pageConfig: {
      init(store, initVariables, component) {
        if (!IS_RETURNING_PATIENT(store.state)) {
          component.enrollInComprehensiveCare = false;
          component.form.questionFour = {
            variableName: 'enrollInComprehensiveCare',
            displayText: null,
            noteLabel: 'Enroll in Comprehensive Care',
            show: true,
            value: null,
          };
        }
      },
    },
  },
});

const RegisterSchedule = createPage({
  name: 'RegisterSchedule',
  nextPage: 'RegisterConfirm',
});
const RegisterConfirm = createPage({
  name: 'RegisterConfirm',
  nextPage: 'RegisterComplete',
});
const RegisterSurvey = createPage({
  name: 'RegisterSurvey',
  nextPage: 'RegisterSchedule',
  options: {
    pageConfig: {
      init: async (store) => {
        const choice = ENROLLED_IN_COMPREHENSIVE_CARE(store.state) ? DETAILED : SIMPLE;

        const FORMS = {
          [PROD]: {
            [SIMPLE]: 'p_ocWqLI7o605KdI7ECyoG',
            [DETAILED]: 'p_hxuHOHFd2tmzL08En8wD',
          },
          [QA]: {
            [SIMPLE]: 'p_ocWqLI7o605KdI7ECyoG',
            [DETAILED]: 'p_hxuHOHFd2tmzL08En8wD',
          },
          [DEV]: {
            [SIMPLE]: 'p_3Tp03q7acPETSdcpaICF',
            [DETAILED]: 'p_yZLSeSElduuBp6zEqevS',
          },
        };
        const form = FORMS[getEnvironment()][choice];

        await store.commit('setRegistration', { form });
      },
      onSurveyCreate: (state, survey) => {
        survey.data = {
          ...survey.data,
          firstName: FIRST_NAME(state),
          lastName: LAST_NAME(state),
          gender: GENDER(state),
          primaryComplaint: PRIMARY_COMPLAINT(state),
          dateOfBirth: DATE_OF_BIRTH(state),
        };
      },

      showEndSplash: false,
    },
  },
});

const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: 'RegisterThankyou',
  options: {
    pageConfig: {
      buttonText: i18n.t('main.continue'),
      cardText: i18n.t('registration.registerComplete.trt.cardText'),
      cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
      isEndOfRegistration: false,
      checkForExistingUser: true,
      clickToContinue: false,
    },
  },
});

const RegisterThankyou = createPage({
  name: 'RegisterThankyou',
  nextPage: END,
});

export default createFlow({
  landing: 'RegisterNewReturning',
  onError: () => {
    return 'RegisterNewReturning';
  },
  flow: {
    RegisterNewReturning,
    RegisterIntroduceNew,
    RegisterIntroduceReturning,
    RegisterInform,
    RegisterSchedule,
    RegisterSurvey,
    RegisterConfirm,
    RegisterComplete,
    RegisterThankyou,
  },
});
