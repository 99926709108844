import { PROD, QA, DEV } from '../constants/env';

export default () => {
  const sourceUrl = window.location.href;
  if (/portal.sentryhealth.ca/g.test(sourceUrl)) {
    return PROD;
  }
  if (/portal.qa.sentryhealth.dev/g.test(sourceUrl)) {
    return QA;
  }
  return DEV;
};
