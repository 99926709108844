/* eslint-disable no-param-reassign */
import { createPage, createFlow } from '../utils';
import { END } from '../variables';
import {
  DATE_OF_BIRTH,
  FIRST_NAME,
  GENDER,
  IS_RETURNING_PATIENT,
  LAST_NAME,
  PRIMARY_COMPLAINT,
} from '../../store';
import i18n from '../../../plugins/i18n';

const RegisterIntroduce = createPage({
  name: 'RegisterIntroduce',
  nextPage: 'RegisterInform',
  options: {
    pageConfig: {
      isEmailOptional: true,
      showEmail: true,
    },
  },
});

const RegisterInform = createPage({
  name: 'RegisterInform',
  nextPage: 'RegisterComplete',
});

const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return 'RegisterThankyou';
    }
    return 'RegisterIntakeChoice';
  },
  options: {
    pageConfig: {
      checkForExistingUser: true,
      isEndOfRegistration: false,
      buttonText: i18n.t('main.continue'),
      cardText: i18n.t('registration.registerComplete.trt.cardText'),
      cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
    },
  },
});

const RegisterIntakeChoice = createPage({
  name: 'RegisterIntakeChoice',
  nextPage: 'RegisterSurvey',
  options: {
    showFooter: false,
  },
});

const RegisterSurvey = createPage({
  name: 'RegisterSurvey',
  nextPage: 'RegisterThankyou',
  options: {
    pageConfig: {
      onSurveyCreate: (state, survey) => {
        survey.data = {
          ...survey.data,
          firstName: FIRST_NAME(state),
          lastName: LAST_NAME(state),
          gender: GENDER(state),
          primaryComplaint: PRIMARY_COMPLAINT(state),
          dateOfBirth: DATE_OF_BIRTH(state),
        };
      },
    },
  },
});

const RegisterThankyou = createPage({
  name: 'RegisterThankyou',
  nextPage: END,
});

export default createFlow({
  landing: 'RegisterIntroduce',
  onError: () => {
    return 'RegisterIntroduce';
  },
  flow: {
    RegisterIntroduce,
    RegisterInform,
    RegisterComplete,
    RegisterIntakeChoice,
    RegisterSurvey,
    RegisterThankyou,
  },
});
