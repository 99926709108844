import { createPage, createFlow } from '../utils';
import { END } from '../variables';
import { REGISTRATION_ERRORS } from '../../errors';
import i18n from '../../../plugins/i18n';

const RegisterVisit = createPage({
  name: 'RegisterVisit',
  nextPage: 'RegisterSchedule',
});
const RegisterSchedule = createPage({
  name: 'RegisterSchedule',
  nextPage: 'RegisterIntroduce',
});
const RegisterIntroduce = createPage({
  name: 'RegisterIntroduce',
  nextPage: 'RegisterInform',
  options: {
    pageConfig: {
      isAddressOptional: true,
      isEmailOptional: true,
      showEmail: true,
      showAddress: true,
    },
  },
});
const RegisterInform = createPage({
  name: 'RegisterInform',
  nextPage: 'RegisterConfirm',
});
const RegisterConfirm = createPage({
  name: 'RegisterConfirm',
  nextPage: 'RegisterComplete',
});

const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: 'RegisterLogout',
  options: {
    pageConfig: {
      buttonText: i18n.t('main.continue'),
      cardText: i18n.t('registration.registerComplete.trt.cardText'),
      cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
      isEndOfRegistration: true,
      checkForExistingUser: true,
    },
  },
});
const RegisterLogout = createPage({
  name: 'RegisterLogout',
  nextPage: 'RegisterAssessment',
});
const RegisterAssessment = createPage({
  name: 'RegisterAssessment',
  nextPage: 'RegisterThankyou',
});

const RegisterThankyou = createPage({
  name: 'RegisterThankyou',
  nextPage: END,
});

const demo = createFlow({
  landing: 'RegisterVisit',
  onError: (errorType) => {
    if (errorType === REGISTRATION_ERRORS.EMAIL_TAKEN) {
      return 'RegisterIntroduce';
    }
    if (errorType === REGISTRATION_ERRORS.CREATE_USER) {
      return 'RegisterIntroduce';
    }
    return 'RegisterSchedule';
  },
  flow: {
    RegisterVisit,
    RegisterSchedule,
    RegisterIntroduce,
    RegisterInform,
    RegisterConfirm,
    RegisterComplete,
    RegisterLogout,
    RegisterAssessment,
    RegisterThankyou,
  },
});

export default demo;
