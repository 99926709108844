import { createPage, createFlow } from '../utils';
import { REPEAT } from '../variables';

const RegisterSurvey = createPage({
  name: 'RegisterSurvey',
  nextPage: REPEAT,
  options: {
    clearHistory: true,
  },
});

const RegisterIntroduce = createPage({
  name: 'RegisterIntroduce',
  nextPage: 'RegisterComplete',
  options: {
    pageConfig: {
      isEmailOptional: true,
      showEmail: false,
    },
  },
});

const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: 'RegisterSurvey',
  options: {
    pageConfig: {
      checkForExistingUser: true,
      isEndOfRegistration: false,
    },
  },
});

export default createFlow({
  landing: 'RegisterIntroduce',
  onError: () => {
    return 'RegisterIntroduce';
  },
  flow: {
    RegisterIntroduce,
    RegisterComplete,
    RegisterSurvey,
  },
});
