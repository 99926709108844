export default [
  'Agmedica',
  'Aurora Cannabis',
  'Canna Farms',
  'Cannalogue',
  'Emblem Cannabis',
  'Estora',
  'Greentec',
  'GYO',
  'Herbal Dispatch',
  'Hybrid Pharm',
  'Mendo',
  'Nabilone',
  'Other',
  'Redecan',
  'Sativex',
  'SDM',
  'Shoppers',
  'Spectrum Therapeutics',
  'Starseed',
  'Tilray Medical',
];
