import { createPage, createFlow } from '../utils';
import { MALE } from '../../gender';
import i18n from '../../../plugins/i18n';
import { REPEAT } from '../variables';
import { APPOINTMENT_TYPES } from '../../appointment';
import { NODE_ENV } from '../../env';
import { PRETTY_DATE_FORMAT, TWELVE_HOUR_TIME_FORMAT } from '../../moment';
import { IS_RETURNING_PATIENT } from '../../store';

const RegisterNewReturningTwo = createPage({
  name: 'RegisterNewReturningTwo',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return 'RegisterIntroduceReturning';
    }
    return 'RegisterIntroduce';
  },
  options: {
    showFooter: false,
    hideHeader: true,
    pageConfig: {
      init: (store) => {
        store.commit('setRegistration', {
          restrictShiftsByEmployee: false,
        });
      },
    },
  },
});
const RegisterIntroduceReturning = createPage({
  name: 'RegisterIntroduceReturning',
  componentName: 'RegisterIntroduce',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return 'RegisterInform';
    }
    return 'RegisterIntroduce';
  },
  options: {
    pageConfig: {
      checkUserAttempts: 3,
      checkForExistingUser: true,
      isEmailOptional: true,
      showConsent: false,
      showEmail: false,
      showFirstName: false,
      showLastName: false,
      showDob: false,
      showPhone: false,
      showGender: false,
      healthCardVersionDisabled: true,
      healthCardExpiryDisabled: true,
      pageTitle: 'Welcome back, please enter your details',
      pageSubtitle:
        "Help us identify you by entering your health card number and date of birth. This is for returning Sentry patients. If you require help, please don't hesitate to message us.",
      oneRow: true,
      returning: true,
    },
  },
});
const RegisterIntroduce = createPage({
  name: 'RegisterIntroduce',
  nextPage: 'RegisterInform',
  options: {
    pageConfig: {
      healthCardExpiryOptional: true,
      healthCardVersionOptional: true,
      isEmailOptional: true,
      showGender: true,
      showEmail: true,
      showAddress: true,
      showPoBox: true,
      pageTitle: 'Please, tell us a bit about yourself',
      patientData: {
        gender: MALE,
      },
    },
  },
});
const RegisterInform = createPage({
  name: 'RegisterInform',
  nextPage: 'RegisterSchedule',
  options: {
    pageConfig: {
      init: (store) => {
        store.commit('setRegistration', {
          // Set default reason for visit to 'Cannabis'
          memberAttributes: ['Cannabis'],
          // Set default reason for referral to 'non-psych'
          additionalComments: 'Non-psych',
          // Set appt type to 'video'
          appointmentType: APPOINTMENT_TYPES.video,
        });
        // Set default answer to three questions to 'false'
        store.commit('addDefaultPatientNotes', {
          basicNotes: [
            'Has had annual checkup?: false',
            'Specialist Requested: false',
            'Do you have a family physician?: false',
          ],
        });
      },
      commPreferenceSubtitle: i18n.t('registration.registerInform.videoCallCaveat'),
      enabledAppointmentTypes: [APPOINTMENT_TYPES.video],
      reasonForVisit: 'Cannabis referral',
      pageTitle: 'Please tell us how we can help you',
      pageSubtitle:
        'Please describe your medical condition in detail. If we do not have enough information we may call you to gather additional details.',
      form: {
        questionOne: {
          variableName: 'annualCheckup',
          displayText: null,
          noteLabel: 'Has had annual checkup?',
          infoText: null,
          show: true,
          value: false,
        },
        questionTwo: {
          variableName: 'seeSpecialist',

          displayText: null,
          noteLabel: 'Specialist Requested',
          show: true,
          value: null,
        },
        questionThree: {
          variableName: 'hasFamilyPhysician',
          displayText: null,
          noteLabel: 'Do you have a family physician?',
          infoText: null,
          show: true,
          value: false,
        },
      },
    },
  },
});

const skills = {
  DEV: 'wm4lOS9WA87QmVLwip0r',
  QA: 'MjTfmhTwXdon6PTlhQGw',
  PROD: 'S4Udxb9kT0TuIXXhKNWo',
};
const RegisterSchedule = createPage({
  name: 'RegisterSchedule',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return 'RegisterComplete';
    }
    return 'RegisterSurvey';
  },
  options: {
    pageConfig: {
      // eslint-disable-next-line no-unused-vars
      init: async (store) => {
        if (!store) return;
        const tenantUrl = store.state.registration.tenantUrl || localStorage.tenantUrl;
        if (NODE_ENV === 'development' && tenantUrl === 'demo') {
          store.commit('setRegistration', { appointmentSkills: [skills.DEV] });
        } else if (NODE_ENV === 'qa' && tenantUrl === 'demo') {
          store.commit('setRegistration', { appointmentSkills: [skills.QA] });
        } else if (NODE_ENV === 'production' && tenantUrl === 'sentry-health') {
          store.commit('setRegistration', { appointmentSkills: [skills.PROD] });
        }
      },
      allowAppointmentRequest: false,
      appointmentDuration: 10,
      pageTitle: 'Please select an appointment time',
      pageSubtitle: ' ',
    },
  },
});

const RegisterSurvey = createPage({
  name: 'RegisterSurvey',
  nextPage: 'RegisterComplete',
  options: {
    backButtonHeader: true,
  },
});

const attributes = {
  DEV: ['7vqaU5R3PUjDhGYTG3uw', 'NCCQFMhZQBJXMKW2I4tD'],
  QA: ['OpOkLERA3FePWb3Bca5g', '3nUFWT18iigDQXTQeg59'],
  PROD: ['3cz2wScF6TrUiJiw7PEs', 't5xArkpeDrRLWUOvNE7c'],
};
const languageAttributes = {
  DEV: {
    english: 'iCpcyteXihl8vt9I8rmf',
    french: 'RjizqTM6cDRm81t7Yr4y',
  },
  QA: {
    english: 'LydUmnz0uoUjd6kPLo2s',
    french: 'mdbHTZTm3jADdAKn9mJU',
  },
  PROD: {
    english: 'MepkPt9GioQCywYF3Inp',
    french: 'epPOO4jSrEa1xbgjWx7q',
  },
};
const locations = {
  DEV: 'nJU10TXxWQCNu9tu9v9j',
  QA: '0W4rOMWPDCtwMv1fj0np',
  PROD: 'k3ei7ZYD6Su0kLWu1QtI',
};
const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: 'RegisterFileUpload',
  options: {
    pageConfig: {
      init: async (store, vars, vm) => {
        if (!store) return;
        const isFrench = vm.$route.query.lang === 'fr';
        const tenantUrl = store.state.registration.tenantUrl || localStorage.tenantUrl;
        if (NODE_ENV === 'development' && tenantUrl === 'demo') {
          store.commit('setRegistration', {
            memberAttributes: [
              ...attributes.DEV,
              isFrench ? languageAttributes.DEV.french : languageAttributes.DEV.english,
            ],
          });
          store.commit('setLocation', { id: locations.DEV });
        } else if (NODE_ENV === 'qa' && tenantUrl === 'demo') {
          store.commit('setRegistration', {
            memberAttributes: [
              ...attributes.QA,
              isFrench ? languageAttributes.QA.french : languageAttributes.QA.english,
            ],
          });
          store.commit('setLocation', { id: locations.QA });
        } else if (NODE_ENV === 'production' && tenantUrl === 'sentry-health') {
          store.commit('setRegistration', {
            memberAttributes: [
              ...attributes.PROD,
              isFrench ? languageAttributes.PROD.french : languageAttributes.PROD.english,
            ],
          });
          // Virtual clinic
          store.commit('setLocation', { id: locations.PROD });
        }
      },
      buttonText: i18n.t('main.continue'),
      cardText: i18n.t('registration.registerComplete.defaultFlow.cardText'),
      cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
      clickToContinue: false,
      isEndOfRegistration: false,
      checkForExistingUser: true,
    },
  },
});

const RegisterFileUpload = createPage({
  name: 'RegisterFileUpload',
  nextPage: 'RegisterLogout',
  options: {
    clearHistory: true,
    pageConfig: {
      init: (store, vars, vm) => {
        const fields = [];
        if (store.state.registration.patientData.firstName) {
          fields.push(
            `Patient: ${i18n.t('format.fullName', store.state.registration.patientData)}`
          );
        }
        fields.push(`Provider: ${store.state.registration.employeeName}`);
        fields.push(
          `Appointment: ${store.state.registration.appointmentTime.format(
            `${PRETTY_DATE_FORMAT} [at] ${TWELVE_HOUR_TIME_FORMAT}`
          )}`
        );
        // eslint-disable-next-line no-param-reassign
        vm.customModalSubtitle = fields.join('\n');
      },
      isEndOfRegistration: true,
      leadLocations: [],
      pageTitle: 'Please upload relevant medical file(s)',
      maxFiles: 25,
    },
  },
});

const RegisterLogout = createPage({
  name: 'RegisterLogout',
  nextPage: REPEAT,
  options: {
    clearHistory: true,
  },
});

const vivo = createFlow({
  landing: 'RegisterNewReturningTwo',
  onError: 'RegisterIntroduce',
  theme: 'vivo',
  type: 'cannabis',
  flow: {
    RegisterNewReturningTwo,
    RegisterIntroduceReturning,
    RegisterIntroduce,
    RegisterInform,
    RegisterSchedule,
    RegisterSurvey,
    RegisterComplete,
    RegisterFileUpload,
    RegisterLogout,
  },
});

export default vivo;
