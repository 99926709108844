import anova from './flows/anova';
import aurora from './flows/aurora';
import belleville from './flows/belleville';
import cannabis from './flows/cannabis';
import ccc from './flows/ccc';
import covidcare from './flows/covidcare';
import demo from './flows/demo';
import def from './flows/default';
import digitalintake from './flows/digitalintake';
import grace from './flows/grace';
import hybridpharm from './flows/hybridpharm';
import mendo from './flows/mendo';
import noble from './flows/noble';
import pwa from './flows/pwa';
import sensimed from './flows/sensimed';
import trt from './flows/trt';
import trt2 from './flows/trt2';
import errorFlow from './flows/errorFlow';
import register2 from './flows/register2';
import survey from './flows/survey';
import wholehealth from './flows/wholehealth';
import woodbridgeTransfer from './flows/woodbridge-transfer';
import vivo from './flows/vivo';

export const ENABLED_FLOWS = [
  'anova',
  'aurora',
  'cannabis',
  'ccc',
  'hybridpharm',
  'mendo',
  'noble',
  'pwa',
  'sensimed',
  'vivo',
  'woodbridge-transfer',
];

export default {
  tenant: {
    default: def,
    wholehealth,
  },
  global: {
    anova,
    aurora,
    belleville,
    cannabis,
    ccc,
    covidcare,
    digitalintake,
    errorFlow,
    hybridpharm,
    mendo,
    noble,
    pharmacy: demo,
    pwa,
    'grace-pharmacy': grace,
    sensimed,
    trt,
    trt2,
    register2,
    survey,
    vivo,
    'woodbridge-transfer': woodbridgeTransfer,
  },
};
