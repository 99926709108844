import { createPage, createFlow } from '../utils';
import { MALE } from '../../gender';
import i18n from '../../../plugins/i18n';
import { REPEAT } from '../variables';

const RegisterIntroduce = createPage({
  name: 'RegisterIntroduce',
  nextPage: 'RegisterSchedule',
  options: {
    pageConfig: {
      checkForExistingUser: true,
      healthCardExpiryOptional: true,
      healthCardVersionOptional: true,
      isEmailOptional: true,
      showGender: true,
      showEmail: true,
      showAddress: true,
      showPoBox: true,
      pageTitle: i18n.t('registration.cannabis.registerIntroduce.pageTitle'),
      patientData: {
        gender: MALE,
      },
    },
  },
});
const RegisterSchedule = createPage({
  name: 'RegisterSchedule',
  nextPage: 'RegisterComplete',
  options: {
    pageConfig: {
      allowAppointmentRequest: false,
      appointmentDuration: 15,
      pageTitle: i18n.t('registration.cannabis.registerSchedule.pageTitle'),
      pageSubtitle: i18n.t('registration.cannabis.registerSchedule.pageSubtitle'),
    },
  },
});
const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: 'RegisterLogout',
  options: {
    pageConfig: {
      buttonText: i18n.t('main.continue'),
      cardText: i18n.t('registration.registerComplete.defaultFlow.cardText'),
      cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
      clickToContinue: true,
      isEndOfRegistration: true,
      checkForExistingUser: true,
      sendNotification: false,
    },
  },
});

const RegisterLogout = createPage({
  name: 'RegisterLogout',
  nextPage: REPEAT,
  options: {
    clearHistory: true,
  },
});

const woodbridgeTransfer = createFlow({
  landing: 'RegisterIntroduce',
  onError: 'RegisterIntroduce',
  flow: {
    RegisterIntroduce,
    RegisterSchedule,
    RegisterComplete,
    RegisterLogout,
  },
});

export default woodbridgeTransfer;
