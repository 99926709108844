import moment from 'moment';
import { createPage, createFlow } from '../utils';
import { MALE } from '../../gender';
import i18n from '../../../plugins/i18n';
import { REPEAT } from '../variables';
import { APPOINTMENT_TYPES } from '../../appointment';
import { NODE_ENV } from '../../env';
import { PRETTY_DATE_FORMAT, TIME_FORMAT } from '../../moment';

const RegisterVisit = createPage({
  name: 'RegisterVisit',
  nextPage: 'RegisterIntroduce',
  options: {
    pageConfig: {
      pageTitle: 'Select a Provider',
      dense: true,
    },
  },
});
const RegisterIntroduce = createPage({
  name: 'RegisterIntroduce',
  nextPage: 'RegisterInform',
  options: {
    pageConfig: {
      healthCardExpiryOptional: true,
      isAddressOptional: false,
      isEmailOptional: false,
      showGender: true,
      showEmail: true,
      showAddress: true,
      pageTitle: 'Please, tell us a bit about yourself',
      patientData: {
        gender: MALE,
      },
    },
  },
});
const RegisterInform = createPage({
  name: 'RegisterInform',
  nextPage: 'RegisterSchedule',
  options: {
    pageConfig: {
      init: (store, route) => {
        store.commit('setRegistration', {
          memberAttributes: ['Fertility', 'Anova'],
          // Set appt type to 'video'
          appointmentType: APPOINTMENT_TYPES.video,
        });
        // Set default answer to three questions to 'false'
        store.commit('addDefaultPatientNotes', {
          basicNotes: [
            'Has had annual checkup?: false',
            'Specialist Requested: false',
            'Do you have a family physician?: false',
          ],
        });

        const availableEmployees =
          route.query?.e?.split('-') ||
          (store?.state?.registration?.employeeId && [store?.state?.registration?.employeeId]) ||
          [];

        if (availableEmployees?.length) store.commit('setRegistration', { availableEmployees });
      },
      enabledAppointmentTypes: [APPOINTMENT_TYPES.video],
      isReasonForVisitFixed: true,
      reasonForVisit: 'Fertility referral',
      pageTitle: 'Please tell us how we can help you',
      pageSubtitle:
        'Please describe your medical condition in detail. If we do not have enough information we may call you to gather additional details.',
      form: {
        questionOne: {
          variableName: 'annualCheckup',
          displayText: null,
          noteLabel: 'Has had annual checkup?',
          infoText: null,
          show: true,
          value: false,
        },
        questionTwo: {
          variableName: 'seeSpecialist',

          displayText: null,
          noteLabel: 'Specialist Requested',
          show: true,
          value: null,
        },
        questionThree: {
          variableName: 'hasFamilyPhysician',
          displayText: null,
          noteLabel: 'Do you have a family physician?',
          infoText: null,
          show: true,
          value: false,
        },
      },
    },
  },
});

const skills = {
  DEV: ['N2XxYVfAmghU2ZApGKOt', 'LMYE4hVRgyKAV3aa0F9E'],
  QA: ['sOFKNYZeKmKa6wPQHwen', 'NR4B9EFrhpmA9EiauYY8'],
  PROD_DEMO: ['2UvVYn8k0eRf6jxgx070', 'ZJdC3ymYZB7QscEOKnlT'],
  PROD: ['z0WU4oX51GLTdFe1af0W'],
};
const RegisterSchedule = createPage({
  name: 'RegisterSchedule',
  nextPage: 'RegisterComplete',
  options: {
    pageConfig: {
      bufferMinutesV2: moment().add(1, 'day').startOf('day').diff(moment(), 'minutes'),
      // eslint-disable-next-line no-unused-vars
      init: async (store) => {
        if (!store) return;
        const tenantUrl = store.state.registration.tenantUrl || localStorage.tenantUrl;
        if (NODE_ENV === 'development' && tenantUrl === 'demo') {
          store.commit('setRegistration', { appointmentSkills: skills.DEV });
        } else if (NODE_ENV === 'qa' && tenantUrl === 'demo') {
          store.commit('setRegistration', { appointmentSkills: skills.QA });
        } else if (NODE_ENV === 'production' && tenantUrl === 'demo') {
          store.commit('setRegistration', { appointmentSkills: skills.PROD_DEMO });
        } else if (NODE_ENV === 'production' && tenantUrl === 'sentry-health') {
          store.commit('setRegistration', { appointmentSkills: skills.PROD });
        }
      },
      allowAppointmentRequest: false,
      appointmentDuration: 10,
      pageTitle: 'Please select an appointment time',
      pageSubtitle: ' ',
    },
  },
});
const attributes = {
  DEV: ['EcAtgkIDadSvynh9Ts5J', 'PJm0T3pIeNUkS5yH65wQ'],
  QA: ['JpGxqfYWZPoQVnQoWzTo', 'HvefqjO6W7c6aleqCJMc'],
  PROD_DEMO: ['hnEbqiWbUcvMLZkBuzHZ', 'yn86lPYKWNFtDEKbOUXQ'],
  PROD: ['QIlahXymq8KQFTHAbV53', 'MNI23rQ8xUhZkJbn27wl'],
};
const languageAttributes = {
  DEV: {
    english: 'iCpcyteXihl8vt9I8rmf',
    french: 'RjizqTM6cDRm81t7Yr4y',
  },
  QA: {
    english: 'LydUmnz0uoUjd6kPLo2s',
    french: 'mdbHTZTm3jADdAKn9mJU',
  },
  PROD: {
    english: 'MepkPt9GioQCywYF3Inp',
    french: 'epPOO4jSrEa1xbgjWx7q',
  },
};
const locations = {
  DEV: 'nJU10TXxWQCNu9tu9v9j',
  QA: '0W4rOMWPDCtwMv1fj0np',
  PROD: 'k3ei7ZYD6Su0kLWu1QtI',
};
const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: 'RegisterFileUpload',
  options: {
    pageConfig: {
      checkForExistingUser: true,
      init: async (store, vars, vm) => {
        if (!store) return;
        const isFrench = vm.$route.query.lang === 'fr';
        const tenantUrl = store.state.registration.tenantUrl || localStorage.tenantUrl;
        if (NODE_ENV === 'development' && tenantUrl === 'demo') {
          store.commit('setRegistration', {
            memberAttributes: [
              ...attributes.DEV,
              isFrench ? languageAttributes.DEV.french : languageAttributes.DEV.english,
            ],
          });
          store.commit('setLocation', { id: locations.DEV });
        } else if (NODE_ENV === 'qa' && tenantUrl === 'demo') {
          store.commit('setRegistration', {
            memberAttributes: [
              ...attributes.QA,
              isFrench ? languageAttributes.QA.french : languageAttributes.QA.english,
            ],
          });
          store.commit('setLocation', { id: locations.QA });
        } else if (NODE_ENV === 'production' && tenantUrl === 'demo') {
          store.commit('setRegistration', {
            memberAttributes: [
              ...attributes.PROD_DEMO,
              isFrench ? languageAttributes.PROD.french : languageAttributes.PROD.english,
            ],
          });
          // Virtual clinic
          store.commit('setLocation', { id: locations.PROD });
        } else if (NODE_ENV === 'production' && tenantUrl === 'sentry-health') {
          store.commit('setRegistration', {
            memberAttributes: [
              ...attributes.PROD,
              isFrench ? languageAttributes.PROD.french : languageAttributes.PROD.english,
            ],
          });
          // Virtual clinic
          store.commit('setLocation', { id: locations.PROD });
        }
      },
      buttonText: i18n.t('main.continue'),
      cardText: i18n.t('registration.registerComplete.defaultFlow.cardText'),
      cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
      clickToContinue: false,
      isEndOfRegistration: false,
    },
  },
});

const RegisterFileUpload = createPage({
  name: 'RegisterFileUpload',
  nextPage: 'RegisterLogout',
  options: {
    clearHistory: true,
    pageConfig: {
      init: (store, vars, vm) => {
        // eslint-disable-next-line no-param-reassign
        vm.customModalSubtitle = `Patient: ${i18n.t(
          'format.fullName',
          store.state.registration.patientData
        )}
        Provider: ${store.state.registration.employeeName}
        Appointment: ${store.state.registration.appointmentTime.format(
          `${PRETTY_DATE_FORMAT} [at] ${TIME_FORMAT}`
        )}
      `;
      },
      isEndOfRegistration: true,
      leadLocations: [],
      pageTitle: 'Please upload relevant medical file(s)',
      maxFiles: 25,
    },
  },
});

const RegisterLogout = createPage({
  name: 'RegisterLogout',
  nextPage: REPEAT,
  options: {
    clearHistory: true,
  },
});

const anova = createFlow({
  landing: 'RegisterVisit',
  theme: 'anova',
  onError: 'RegisterIntroduce',
  flow: {
    RegisterVisit,
    RegisterIntroduce,
    RegisterInform,
    RegisterSchedule,
    RegisterComplete,
    RegisterFileUpload,
    RegisterLogout,
  },
});

export default anova;
