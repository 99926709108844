/* eslint-disable no-unused-vars */
import { createPage, createFlow } from '../utils';
import { MALE } from '../../gender';
import i18n from '../../../plugins/i18n';
import { REPEAT } from '../variables';
import { APPOINTMENT_TYPES } from '../../appointment';
import { NODE_ENV } from '../../env';
import { PRETTY_DATE_FORMAT, TWELVE_HOUR_TIME_FORMAT } from '../../moment';
import { IS_RETURNING_PATIENT } from '../../store';

const RegisterNewReturningTwo = createPage({
  name: 'RegisterNewReturningTwo',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return 'RegisterIntroduceReturning';
    }
    return 'RegisterIntroduce';
  },
  options: {
    showFooter: false,
    skipOnNoIntro: true,
    hideHeader: true,
    pageConfig: {
      init: (store) => {
        store.commit('setRegistration', {
          restrictShiftsByEmployee: false,
        });
      },
      para1: i18n.t('registration.cannabis.registerNewReturning.para1'),
    },
  },
});
const RegisterIntroduceReturning = createPage({
  name: 'RegisterIntroduceReturning',
  componentName: 'RegisterIntroduce',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return 'RegisterInform';
    }
    return 'RegisterIntroduce';
  },
  options: {
    pageConfig: {
      checkUserAttempts: 3,
      checkForExistingUser: true,
      isEmailOptional: true,
      showConsent: false,
      showEmail: false,
      showFirstName: false,
      showLastName: false,
      showDob: false,
      showPhone: false,
      showGender: false,
      healthCardVersionDisabled: true,
      healthCardExpiryDisabled: true,
      pageTitle: i18n.t('registration.cannabis.registerIntroduceReturning.pageTitle'),
      pageSubtitle: i18n.t('registration.cannabis.registerIntroduceReturning.pageSubtitle'),
      oneRow: true,
      returning: true,
    },
  },
});
const RegisterIntroduce = createPage({
  name: 'RegisterIntroduce',
  nextPage: 'RegisterInform',
  options: {
    pageConfig: {
      checkForExistingUser: true,
      healthCardExpiryOptional: true,
      healthCardVersionOptional: true,
      isEmailOptional: true,
      showGender: true,
      showEmail: true,
      showAddress: true,
      showPoBox: true,
      pageTitle: i18n.t('registration.cannabis.registerIntroduce.pageTitle'),
      patientData: {
        gender: MALE,
      },
    },
  },
});
const RegisterInform = createPage({
  name: 'RegisterInform',
  nextPage: 'RegisterSchedule',
  options: {
    pageConfig: {
      init: (store) => {
        store.commit('setRegistration', {
          // Set default reason for visit to 'Cannabis'
          memberAttributes: ['Cannabis'],
          // Set default reason for referral to 'non-psych'
          additionalComments: 'Non-psych',
          // Set appt type to 'video'
          appointmentType: APPOINTMENT_TYPES.video,
        });
        if (store.state.registration.patientData.notes.basicNotes.length) return;

        // Set default answer to three questions to 'false'
        store.commit('addDefaultPatientNotes', {
          basicNotes: [
            `${i18n.t('registration.cannabis.registerInform.form.questionOne')}: false`,
            `${i18n.t('registration.cannabis.registerInform.form.questionTwo')}: false`,
            `${i18n.t('registration.cannabis.registerInform.form.questionThree')}: false`,
            `${i18n.t('registration.cannabis.registerInform.form.isVeteran')}: false`,
            `${i18n.t('registration.cannabis.registerInform.form.kNumber')}: `,
          ],
        });
      },
      commPreferenceSubtitle: i18n.t('registration.registerInform.videoCallCaveat'),
      enabledAppointmentTypes: [APPOINTMENT_TYPES.video],
      reasonForVisit: 'Cannabis referral',
      pageTitle: i18n.t('registration.cannabis.registerInform.pageTitle'),
      pageSubtitle: i18n.t('registration.cannabis.registerInform.pageSubtitle'),

      form: {
        questionOne: {
          variableName: 'annualCheckup',
          displayText: null,
          noteLabel: i18n.t('registration.cannabis.registerInform.form.questionOne'),
          infoText: null,
          show: true,
          value: false,
        },
        questionTwo: {
          variableName: 'seeSpecialist',

          displayText: null,
          noteLabel: i18n.t('registration.cannabis.registerInform.form.questionTwo'),
          show: true,
          value: null,
        },
        questionThree: {
          variableName: 'hasFamilyPhysician',
          displayText: null,
          noteLabel: i18n.t('registration.cannabis.registerInform.form.questionThree'),
          infoText: null,
          show: true,
          value: false,
        },
        questionFour: {
          variableName: 'isVeteran',
          displayText: null,
          noteLabel: i18n.t('registration.cannabis.registerInform.form.isVeteran'),
          infoText: null,
          show: true,
          value: false,
        },
        questionFive: {
          variableName: 'kNumber',
          type: 'input',
          displayText: null,
          noteLabel: i18n.t('registration.cannabis.registerInform.form.kNumber'),
          infoText: null,
          show: true,
          value: null,
          mask: 'K#######',
          showIf: (form) => {
            try {
              const { questionFour } = form;
              const { value } = questionFour;
              return value;
            } catch {
              return false;
            }
          },
        },
      },
    },
  },
});

const skills = {
  DEV: 'wm4lOS9WA87QmVLwip0r',
  QA: 'MjTfmhTwXdon6PTlhQGw',
  PROD: 'S4Udxb9kT0TuIXXhKNWo',
};
const RegisterSchedule = createPage({
  name: 'RegisterSchedule',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return 'RegisterComplete';
    }
    return 'RegisterSurvey';
  },
  options: {
    pageConfig: {
      // eslint-disable-next-line no-unused-vars
      init: async (store) => {
        if (!store) return;
        const tenantUrl = store.state.registration.tenantUrl || localStorage.tenantUrl;
        if (NODE_ENV === 'development' && tenantUrl === 'demo') {
          store.commit('setRegistration', { appointmentSkills: [skills.DEV] });
        } else if (NODE_ENV === 'qa' && tenantUrl === 'demo') {
          store.commit('setRegistration', { appointmentSkills: [skills.QA] });
        } else if (NODE_ENV === 'production' && tenantUrl === 'sentry-health') {
          store.commit('setRegistration', { appointmentSkills: [skills.PROD] });
        }
      },
      allowAppointmentRequest: false,
      appointmentDuration: 10,
      pageTitle: i18n.t('registration.cannabis.registerSchedule.pageTitle'),
      pageSubtitle: i18n.t('registration.cannabis.registerSchedule.pageSubtitle'),
    },
  },
});

const RegisterSurvey = createPage({
  name: 'RegisterSurvey',
  nextPage: 'RegisterComplete',
  options: {
    backButtonHeader: true,
  },
});

const attributes = {
  DEV: ['7vqaU5R3PUjDhGYTG3uw', 'v5y10apuAaxLuZRd27Gv'],
  QA: ['OpOkLERA3FePWb3Bca5g', 'lvje1H5hhWOFzZ5xo0of'],
  PROD: ['3cz2wScF6TrUiJiw7PEs', 'YAIBJ8aDHNCAYr8kx03C'],
};
const languageAttributes = {
  DEV: {
    english: 'iCpcyteXihl8vt9I8rmf',
    french: 'RjizqTM6cDRm81t7Yr4y',
  },
  QA: {
    english: 'LydUmnz0uoUjd6kPLo2s',
    french: 'mdbHTZTm3jADdAKn9mJU',
  },
  PROD: {
    english: 'MepkPt9GioQCywYF3Inp',
    french: 'epPOO4jSrEa1xbgjWx7q',
  },
};
const locations = {
  DEV: 'nJU10TXxWQCNu9tu9v9j',
  QA: '0W4rOMWPDCtwMv1fj0np',
  PROD: 'k3ei7ZYD6Su0kLWu1QtI',
};
const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: 'RegisterFileUpload',
  options: {
    pageConfig: {
      init: async (store, vars, vm) => {
        if (!store) return;
        const isFrench = vm.$route.query.lang === 'fr';
        const tenantUrl = store.state.registration.tenantUrl || localStorage.tenantUrl;
        if (NODE_ENV === 'development' && tenantUrl === 'demo') {
          store.commit('setRegistration', {
            memberAttributes: [
              ...attributes.DEV,
              isFrench ? languageAttributes.DEV.french : languageAttributes.DEV.english,
            ],
          });
          store.commit('setLocation', { id: locations.DEV });
        } else if (NODE_ENV === 'qa' && tenantUrl === 'demo') {
          store.commit('setRegistration', {
            memberAttributes: [
              ...attributes.QA,
              isFrench ? languageAttributes.QA.french : languageAttributes.QA.english,
            ],
          });
          store.commit('setLocation', { id: locations.QA });
        } else if (NODE_ENV === 'production' && tenantUrl === 'sentry-health') {
          store.commit('setRegistration', {
            memberAttributes: [
              ...attributes.PROD,
              isFrench ? languageAttributes.PROD.french : languageAttributes.PROD.english,
            ],
          });
          // Virtual clinic
          store.commit('setLocation', { id: locations.PROD });
        }
      },
      buttonText: i18n.t('main.continue'),
      cardText: i18n.t('registration.registerComplete.defaultFlow.cardText'),
      cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
      clickToContinue: false,
      isEndOfRegistration: false,
      checkForExistingUser: true,
    },
  },
});

const RegisterFileUpload = createPage({
  name: 'RegisterFileUpload',
  nextPage: 'RegisterLogout',
  options: {
    clearHistory: true,
    pageConfig: {
      init: (store, vars, vm) => {
        const fields = [];
        if (store.state.registration.patientData.firstName) {
          fields.push(
            `${i18n.t('main.patient')}: ${i18n.t(
              'format.fullName',
              store.state.registration.patientData
            )}`
          );
        }
        fields.push(`${i18n.t('main.provider')}: ${store.state.registration.employeeName}`);
        fields.push(
          `${i18n.t('main.appointment')}: ${store.state.registration.appointmentTime.format(
            `${PRETTY_DATE_FORMAT} [at] ${TWELVE_HOUR_TIME_FORMAT}`
          )}`
        );
        // eslint-disable-next-line no-param-reassign
        vm.customModalSubtitle = fields.join('\n');
      },
      isEndOfRegistration: true,
      leadLocations: [],
      pageTitle: i18n.t('registration.cannabis.registerFileUpload.pageTitle'),
      maxFiles: 25,
    },
  },
});

const RegisterLogout = createPage({
  name: 'RegisterLogout',
  nextPage: REPEAT,
  options: {
    clearHistory: true,
  },
});

const noble = createFlow({
  landing: 'RegisterNewReturningTwo',
  onError: 'RegisterIntroduce',
  theme: 'noble',
  type: 'cannabis',
  flow: {
    RegisterNewReturningTwo,
    RegisterIntroduceReturning,
    RegisterIntroduce,
    RegisterInform,
    RegisterSchedule,
    RegisterSurvey,
    RegisterComplete,
    RegisterFileUpload,
    RegisterLogout,
  },
});

export default noble;
