// // eslint-disable-next-line import/no-cycle
// import * as componentDefaults from './models';
import { defaultOptions, defaultPages } from './models';

export const createPage = ({ name, componentName, nextPage, options }) => {
  if (!name) throw new Error('Error creating page: no page name provided.');

  /*
  pageBase is either:

  a) the default page template stored in models, or
  b) a blank template

  In either case, it's on object to which we can assign
  custom variables.
   */
  const pageBase = defaultPages[name]
    ? { ...defaultPages[name] }
    : {
        name,
        componentName,
        next: null,
        options: { ...defaultOptions },
      };

  if (!nextPage && !pageBase.next)
    throw new Error(
      'Error creating page: there is no default page and no next function was provided.'
    );

  if (typeof nextPage === 'string') {
    pageBase.next = () => {
      return nextPage;
    };
  }

  if (typeof nextPage === 'function') {
    pageBase.next = (store) => nextPage(store);
  }

  pageBase.options = {
    ...pageBase.options,
    ...options,
    pageConfig: options?.pageConfig
      ? {
          ...pageBase.options?.pageConfig,
          ...options.pageConfig,
        }
      : { ...pageBase.options.pageConfig } || {},
  };

  return pageBase;
};

/* eslint-disable no-param-reassign */
export const modifyPage = (defaultPage, customization) => {
  if (customization.options?.pageConfig) {
    customization.options.pageConfig = {
      ...defaultPage.options.pageConfig,
      ...customization.options.pageConfig,
    };
  }

  customization.options = { ...defaultPage.options, ...customization.options };
  return { ...defaultPage, ...customization };
};

export const createFlow = ({ theme = 'portal', landing, flow, onError, type }) => {
  if (!flow) throw new Error('Cannot create page flow: no pageflow provided');
  if (!landing) throw new Error('Cannot create page flow: no landing page provided');
  if (!onError) throw new Error('Cannot create page flow: error handler not provided');
  if (typeof onError !== 'string' && typeof onError !== 'function')
    throw new Error('Cannot create page flow: error handler must be string or function');

  let handleErrorFunction;
  if (typeof onError === 'string') {
    handleErrorFunction = () => {
      return onError;
    };
  } else if (typeof onError === 'function') {
    handleErrorFunction = (errorType) => {
      return onError(errorType);
    };
  }

  return {
    landing,
    onError: handleErrorFunction,
    theme,
    flow,
    type,
  };
};
