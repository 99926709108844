/*
  Error flow is for the purposes of QA'ing error handling
*/

import { createPage, createFlow } from '../utils';
import i18n from '../../../plugins/i18n';
import { REPEAT } from '../variables';

const RegisterIntroduce = createPage({
  name: 'RegisterIntroduce',
  nextPage: 'RegisterInform',
  options: {
    pageConfig: {
      isEmailOptional: false,
      showGender: true,
      showEmail: true,
    },
  },
});

const RegisterInform = createPage({
  name: 'RegisterInform',
  nextPage: 'RegisterComplete',
});

const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: REPEAT,
  options: {
    pageConfig: {
      buttonText: i18n.t('main.continue'),
      cardText: i18n.t('registration.registerComplete.trt.cardText'),
      cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
      clickToContinue: false,
      isEndOfRegistration: false,
    },
  },
});
const errorFlow = createFlow({
  landing: 'RegisterIntroduce',
  onError: 'RegisterInform',
  flow: {
    RegisterIntroduce,
    RegisterInform,
    RegisterComplete,
  },
});

export default errorFlow;
