/* eslint-disable no-param-reassign */
import { createPage, createFlow } from '../utils';
import i18n from '../../../plugins/i18n';
import { REPEAT } from '../variables';
import { PROD, QA, DEV } from '../../env';
import getEnvironment from '../../../utils/environment';

import {
  IS_RETURNING_PATIENT,
  FIRST_NAME,
  LAST_NAME,
  DATE_OF_BIRTH,
  GENDER,
  PRIMARY_COMPLAINT,
} from '../../store';

const NEW = 'new';
const RETURNING = 'returning';

const RegisterNewReturningTwo = createPage({
  name: 'RegisterNewReturningTwo',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return 'RegisterIntroduceReturning';
    }
    return 'RegisterIntroduceNew';
  },
  options: {
    showFooter: false,
    hideHeader: true,
    pageConfig: {
      init: (store) => {
        const LOCATIONS = {
          [PROD]: 't3tAPV0Q8lFMwvwOFJvY', // sentry-health tenant
          [QA]: 'APdA5gmR6A39U5Iak3VZ', // demo tenant
          [DEV]: 'r8Wx5BciS6Z2ytnNimfg', // demo tenant
        };

        store.commit('setRegistration', {
          location: { id: LOCATIONS[getEnvironment()] },
          employeeId: 'UNASSIGNED',
          restrictShiftsByEmployee: true,
        });
      },
    },
  },
});

const RegisterIntroduceNew = createPage({
  name: 'RegisterIntroduceNew',
  componentName: 'RegisterIntroduce',
  nextPage: 'RegisterInform',
  options: {
    pageConfig: {
      pageTitle: 'Please, tell us a bit about yourself',
      pageSubtitle:
        'This registration is for new patients of Sentry Health only. You may receive an error if you incorrectly enter your health card, version code, or expiry date.',
      isEmailOptional: true,
      showEmail: true,
    },
  },
});
const RegisterIntroduceReturning = createPage({
  name: 'RegisterIntroduceReturning',
  componentName: 'RegisterIntroduce',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return 'RegisterInform';
    }
    return 'RegisterIntroduceNew';
  },
  options: {
    pageConfig: {
      checkUserAttempts: 3,
      checkForExistingUser: true,
      isEmailOptional: true,
      showConsent: false,
      showEmail: false,
      showFirstName: false,
      showLastName: false,
      showDob: true,
      showPhone: false,
      showGender: false,
      healthCardVersionDisabled: true,
      healthCardExpiryDisabled: true,
      pageTitle: 'Welcome back, please enter your details',
      pageSubtitle:
        "Help us identify you by entering your health card number and date of birth. This is for returning Sentry patients. If you require help, please don't hesitate to message us.",
      oneRow: true,
    },
  },
});
const RegisterInform = createPage({
  name: 'RegisterInform',
  nextPage: 'RegisterSurvey',
  options: {
    pageConfig: {
      pageTitle: 'Please tell us how we can help you',
      pageSubtitle:
        'Please describe your medical condition in detail. If we do not have enough information we may call you to gather additional details.',
    },
  },
});

const RegisterSchedule = createPage({
  name: 'RegisterSchedule',
  nextPage: 'RegisterConfirm',
  options: {
    pageConfig: {
      pageTitle: 'Pick a time that works best for you',
      pageSubtitle:
        'Please note, your selected time may not always be available. We will call or text you if there are any changes to your appointment.',
    },
  },
});
const RegisterConfirm = createPage({
  name: 'RegisterConfirm',
  nextPage: 'RegisterComplete',
  options: {
    pageConfig: {
      pageTitle: 'Great! Let’s finalize your details',
      pageSubtitle:
        'Double check to see if everything is correct. We’ll do our best to find you the right physician for your assessment',
      showPicture: false,
    },
  },
});
const RegisterSurvey = createPage({
  name: 'RegisterSurvey',
  nextPage: 'RegisterSchedule',
  options: {
    backButtonHeader: true,
    pageConfig: {
      pageTitle: 'Tell us a bit about your medical history',
      pageSubtitle:
        'Having the right information for your medical assessment is very important. This will help speed up your appointment.',
      init: async (store) => {
        const { tenantUrl } = store.state.registration;
        const isNew = IS_RETURNING_PATIENT(store.state) ? RETURNING : NEW;
        const SANDBOX_FORM = {
          demo: {
            [NEW]: 'p_yZLSeSElduuBp6zEqevS',
            [RETURNING]: null,
          },
        };

        const QA_FORM = {
          demo: {
            [NEW]: 'p_hxuHOHFd2tmzL08En8wD',
            [RETURNING]: null,
          },
        };

        const PROD_FORM = {
          demo: {
            [NEW]: 'p_OEWvl2SgfjhKRH55VMaB',
            [RETURNING]: null,
          },
          'sentry-health': {
            [NEW]: 'p_vE1mMQdwRF0B7SEw8rF5',
            [RETURNING]: 'p_loPMXmgNobgjh3JjUMHg',
          },
        };

        const sourceUrl = window.location.href;

        let form;

        if (/portal.sentryhealth.ca/g.test(sourceUrl)) {
          form = PROD_FORM[tenantUrl][isNew];
        } else if (/portal.qa.sentryhealth.dev/g.test(sourceUrl)) {
          form = QA_FORM[tenantUrl][isNew];
        } else {
          form = SANDBOX_FORM[tenantUrl][isNew];
        }

        await store.commit('setRegistration', { form });
      },
      onSurveyCreate: (state, survey) => {
        survey.data = {
          ...survey.data,
          firstName: FIRST_NAME(state),
          lastName: LAST_NAME(state),
          gender: GENDER(state),
          primaryComplaint: PRIMARY_COMPLAINT(state),
          dateOfBirth: DATE_OF_BIRTH(state),
        };
      },
      showEndSplash: false,
    },
  },
});

const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: 'RegisterGoodbye',
  options: {
    pageConfig: {
      buttonText: i18n.t('main.continue'),
      cardText: i18n.t('registration.registerComplete.trt.cardText'),
      cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
      isEndOfRegistration: false,
      checkForExistingUser: true,
      clickToContinue: false,
    },
  },
});

const RegisterGoodbye = createPage({
  name: 'RegisterGoodbye',
  nextPage: REPEAT,
  options: {
    showFooter: false,
    hideHeader: true,
  },
});

export default createFlow({
  landing: 'RegisterNewReturningTwo',
  onError: () => {
    return 'RegisterNewReturningTwo';
  },
  flow: {
    RegisterNewReturningTwo,
    RegisterIntroduceNew,
    RegisterIntroduceReturning,
    RegisterInform,
    RegisterSchedule,
    RegisterSurvey,
    RegisterConfirm,
    RegisterComplete,
    RegisterGoodbye,
  },
});
