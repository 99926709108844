import { createPage, createFlow } from '../utils';
import { MALE } from '../../gender';
import i18n from '../../../plugins/i18n';
import { APPOINTMENT_TYPES } from '../../appointment';
import { REPEAT } from '../variables';
import { IS_RETURNING_PATIENT } from '../../store';

const RegisterIntroduce = createPage({
  name: 'RegisterIntroduce',
  nextPage: 'RegisterComplete',
  options: {
    pageConfig: {
      isEmailOptional: true,
      showGender: false,
      showEmail: true,
      patientData: {
        gender: MALE,
      },
    },
  },
});

const RegisterComplete = createPage({
  name: 'RegisterComplete',
  nextPage: (state) => {
    if (IS_RETURNING_PATIENT(state)) {
      return REPEAT;
    }
    return 'RegisterFileUpload';
  },
  options: {
    pageConfig: {
      init: (store) => {
        store.commit('setRegistration', {
          appointmentReasons: 'Low Testosterone',
        });
        if (store.state.registration.employeeId) {
          store.commit('setRegistration', {
            appointmentTime: 'asap',
            appointmentType: APPOINTMENT_TYPES.video,
          });
        }
        store.commit('setPatientData', {
          billingAddress: {
            country: 'Canada',
            province: 'Ontario',
          },
          mailingAddress: {
            country: 'Canada',
            province: 'Ontario',
          },
        });
        store.commit('addOtherPatientNote', {
          newNoteText: 'Low Testosterone',
          noteTags: ['Primary Complaint'],
        });
      },
      buttonText: i18n.t('main.continue'),
      cardText: i18n.t('registration.registerComplete.trt.cardText'),
      cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
      clickToContinue: false,
      isEndOfRegistration: false,
      checkForExistingUser: true,
    },
  },
});

const RegisterFileUpload = createPage({
  name: 'RegisterFileUpload',
  nextPage: 'RegisterLogout',
  options: {
    clearHistory: true,
    pageConfig: {
      isEndOfRegistration: true,
    },
  },
});

const RegisterLogout = createPage({
  name: 'RegisterLogout',
  nextPage: REPEAT,
  options: { clearHistory: true },
});

const trt2 = createFlow({
  landing: 'RegisterIntroduce',
  onError: 'RegisterIntroduce',
  flow: {
    RegisterIntroduce,
    RegisterComplete,
    RegisterFileUpload,
    RegisterLogout,
  },
});

export default trt2;
